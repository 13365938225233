import React, { useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import { NavbarWrapper } from "./Header.styles"
import {  } from "react-bootstrap"
import Logo from "../../Global/Layout/Logo/Logo"
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"

const Header = ({
  headerAbsolute,
  headerTransparent,
  logoDark,
  textlight,
  whiteToggler,
}) => {
  const { Brand, Collapse, Toggle } = Navbar
  return (
    <NavbarWrapper
      headerTransparent={headerTransparent}
      expand="lg"
      headerAbsolute={headerAbsolute}
      className={`px-4 px-sm-5 ${whiteToggler ? "navbar-dark" : null}`}
      textlight={textlight}
      id="site-navbar"
    >
      <div className="nav-container w-100 mx-auto my-4">
        <Brand to={LINKS.index} as={Link}>
          <Logo variant={logoDark && "light"} />
        </Brand>
      </div>
    </NavbarWrapper>
  )
}

export default Header
